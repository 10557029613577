import { Suspense, lazy, useState } from "react";
import { useRoutes, Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import { AuthChildren } from "src/components/common/AuthChildren/AuthChildren";
import MainLayout from "src/components/layouts/main/MainLayout";
import AuthLayout from "src/components/layouts/auth/AuthLayout";
import SuspenseLoader from "src/components/suspenseLoader";
// import NotFound from "src/pages/main/NotFound";
import { useProfileQuery } from "src/store/services/profile/profileApi";
import { skipToken } from "@reduxjs/toolkit/query";

// ----------------------------- loader ----------------------------------//
const Loader = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );
const LazyLoader = (promise: Promise<any>, time?: number) =>
  Loader(
    lazy(async () => {
      await new Promise((resolve) => setTimeout(resolve, time ?? 0));
      return promise;
    })
  );

// ----------------------------- loan-request ----------------------------------//
const LoanRequestList = LazyLoader(import("src/pages/main/LoanRequest/LoanRequestList"));

// ----------------------------- loan-request-adddata ----------------------------------//
const LayoutLoanRequestAddData = LazyLoader(import("src/pages/main/LoanRequest/LoanRequestAddData/LayoutLoanRequestAddData"));
const LoanRequestAddDataRoomPlan = LazyLoader(import("src/pages/main/LoanRequest/LoanRequestAddData/LoanRequestAddDataRoomPlan"));
const LoanRequestAddDataUser = LazyLoader(import("src/pages/main/LoanRequest/LoanRequestAddData/LoanRequestAddDataUser"));
const LoanRequestAddDataBank = LazyLoader(import("src/pages/main/LoanRequest/LoanRequestAddData/LoanRequestAddDataBank"));
const LoanRequestAddDataRecord = LazyLoader(import("src/pages/main/LoanRequest/LoanRequestAddData/LoanRequestAddDataRecord"));
const LoanRequestAddDataApprove = LazyLoader(import("src/pages/main/LoanRequest/LoanRequestAddData/LoanRequestAddDataApprove"));

// ----------------------------- loan-request-adduser ----------------------------------//
const LayoutLoanRequestAddUser = LazyLoader(import("src/pages/main/LoanRequest/LoanRequestAddUser/LayoutLoanRequestAddUser"));
const LoanRequestAddUserInformation = LazyLoader(import("src/pages/main/LoanRequest/LoanRequestAddUser/LoanRequestAddUserInformation"));
const LoanRequestAddUserAddress = LazyLoader(import("src/pages/main/LoanRequest/LoanRequestAddUser/LoanRequestAddUserAddress"));
const LoanRequestAddUserDocuments = LazyLoader(import("src/pages/main/LoanRequest/LoanRequestAddUser/LoanRequestAddUserDocuments"));
const LoanRequestAddUserRequestLoan = LazyLoader(import("src/pages/main/LoanRequest/LoanRequestAddUser/LoanRequestAddUserRequestLoan"));

// ----------------------------- loan-status----------------------------------//
const LoanCaseList = LazyLoader(import("src/pages/main/LoanStatus/LoanCaseList"));
const LoanStatusList = LazyLoader(import("src/pages/main/LoanStatus/LoanStatusList"));

const LayoutLoanStatusData = LazyLoader(import("src/pages/main/LoanStatus/LoanStatusData/LayoutLoanStatusData"));
const LoanStatusDataRoomplan = LazyLoader(import("src/pages/main/LoanStatus/LoanStatusData/LoanStatusDataRoomplan"));
const LoanStatusDataLoan = LazyLoader(import("src/pages/main/LoanStatus/LoanStatusData/LoanStatusDataLoan"));
const LoanStatusDataBorrower = LazyLoader(import("src/pages/main/LoanStatus/LoanStatusData/LoanStatusDataBorrower"));
const LoanStatusDataCollateral = LazyLoader(import("src/pages/main/LoanStatus/LoanStatusData/LoanStatusDataCollateral"));
const LoanStatusDataStatus = LazyLoader(import("src/pages/main/LoanStatus/LoanStatusData/LoanStatusDataStatus"));

// ----------------------------- report ----------------------------------//
const Report = LazyLoader(import("src/pages/main/Report"));

// ----------------------------- profile ----------------------------------//
const Profile = LazyLoader(import("src/pages/main/Profile"));

// ----------------------------- profile ----------------------------------//
const Notifications = LazyLoader(import("src/pages/main/Notifications"));

// ----------------------------- setting ----------------------------------//
const LayoutSettingManagement = LazyLoader(import("src/pages/main/Setting/SettingManagement/LayoutSettingManagement"));
const SettingUserManagement = LazyLoader(import("src/pages/main/Setting/SettingManagement/SettingUserManagement"));
const SettingRoleManagement = LazyLoader(import("src/pages/main/Setting/SettingManagement/SettingRoleManagement"));
const SettingTeamManagement = LazyLoader(import("src/pages/main/Setting/SettingManagement/SettingTeamManagement"));
const SettingUserData = LazyLoader(import("src/pages/main/Setting/SettingUserManagement/SettingUserData"));
const SettingRoleData = LazyLoader(import("src/pages/main/Setting/SettingRoleManagement/SettingRoleData"));
const SettingRoleManageUser = LazyLoader(import("src/pages/main/Setting/SettingRoleManagement/SettingRoleManageUser"));
const SettingRoleManageRoleMenu = LazyLoader(import("src/pages/main/Setting/SettingRoleManagement/SettingRoleManagementRole/RoleMenu"));
const SettingRoleManageRolePermission = LazyLoader(
  import("src/pages/main/Setting/SettingRoleManagement/SettingRoleManagementRole/RolePermission")
);

// ----------------------------- components ----------------------------------//
const LayoutComponents = LazyLoader(import("src/pages/main/Components/LayoutComponents"));
const Components1 = LazyLoader(import("src/pages/main/Components/Components1"));
const Components2 = LazyLoader(import("src/pages/main/Components/Components2"));
const Components3 = LazyLoader(import("src/pages/main/Components/Components3"));

// ----------------------------- auth ----------------------------------//
const LoginForm = LazyLoader(import("src/pages/auth/LoginForm"));
const VerifyPasswordForm = LazyLoader(import("src/pages/auth/SetPassword/VerifyPasswordForm"));
const ResetPasswordForm = LazyLoader(import("src/pages/auth/SetPassword/ResetPasswordForm"));
const ForgotPasswordForm = LazyLoader(import("src/pages/auth/ForgotPassword/ForgotPasswordForm"));

// ----------------------------- report ----------------------------------//
const NotFound = LazyLoader(import("src/pages/main/NotFound"));

// ----------------------------- main-routes ----------------------------------//
const protectedLayout = (
  <AuthChildren>
    <MainLayout />
  </AuthChildren>
);
const protectedLayoutHideSidebar = (
  <AuthChildren>
    <MainLayout hideSidebar={true} />
  </AuthChildren>
);

const MainRoutes = {
  path: "/",
  element: protectedLayout,
  children: [
    { element: <Navigate to="/loan/request/list" />, index: true },
    { path: "loan/request/list", element: <LoanRequestList /> },
    {
      path: "loan/request/:id",
      element: <LayoutLoanRequestAddData />,
      children: [
        { element: <Navigate to="roomplan" />, index: true },
        { path: "roomplan", element: <LoanRequestAddDataRoomPlan /> },
        { path: "user", element: <LoanRequestAddDataUser /> },
        { path: "bank", element: <LoanRequestAddDataBank /> },
        { path: "record", element: <LoanRequestAddDataRecord /> },
        { path: "approve", element: <LoanRequestAddDataApprove /> }
      ]
    },
    {
      path: "loan/request/:id/:borrowerId",
      element: <LayoutLoanRequestAddUser />,
      children: [
        { element: <Navigate to="information" />, index: true },
        { path: "information", element: <LoanRequestAddUserInformation /> },
        { path: "address", element: <LoanRequestAddUserAddress /> },
        { path: "requestloan", element: <LoanRequestAddUserRequestLoan /> },
        { path: "documents", element: <LoanRequestAddUserDocuments /> }
      ]
    },
    { path: "loan/case/list", element: <LoanCaseList /> },
    { path: "loan/status/list/:caseCode", element: <LoanStatusList /> },
    {
      path: "loan/status/:caseCode/:loanId/:bankCode",
      element: <LayoutLoanStatusData />,
      children: [
        { element: <Navigate to="roomplan" />, index: true },
        { path: "roomplan", element: <LoanStatusDataRoomplan /> },
        { path: "loan", element: <LoanStatusDataLoan /> },
        { path: "user", element: <LoanStatusDataBorrower /> },
        { path: "collateral", element: <LoanStatusDataCollateral /> },
        { path: "status", element: <LoanStatusDataStatus /> }
      ]
    },
    { path: "report", element: <Report /> },
    {
      path: "setting",
      element: <LayoutSettingManagement />,
      children: [
        { element: <Navigate to="user-management" />, index: true },
        { path: "user-management", element: <SettingUserManagement /> },
        { path: "role-management", element: <SettingRoleManagement /> }
        // { path: "team-management", element: <SettingTeamManagement /> }
      ]
    },
    {
      path: "setting/user-management/:id",
      element: <SettingUserData />
    },
    {
      path: "setting/user-management/1",
      element: <Navigate to="*" />
    },
    {
      path: "setting/role-management/:id",
      element: <SettingRoleData />
    },
    {
      path: "setting/role-management/:id",
      children: [
        // { element: <Navigate to="user" />, index: true },
        { path: "user", element: <SettingRoleManageUser /> },
        { path: "role", element: <SettingRoleManageRoleMenu /> },
        { path: "role/:id", element: <SettingRoleManageRolePermission />, params: { hideSidebar: true } }
      ]
    }
    // { path: "*", element: <NotFound /> }
  ]
};
const MainRoutesNotSetting = {
  path: "/",
  element: protectedLayout,
  children: [
    { element: <Navigate to="/loan/request/list" />, index: true },
    { path: "loan/request/list", element: <LoanRequestList /> },
    {
      path: "loan/request/:id",
      element: <LayoutLoanRequestAddData />,
      children: [
        { element: <Navigate to="roomplan" />, index: true },
        { path: "roomplan", element: <LoanRequestAddDataRoomPlan /> },
        { path: "user", element: <LoanRequestAddDataUser /> },
        { path: "bank", element: <LoanRequestAddDataBank /> },
        { path: "record", element: <LoanRequestAddDataRecord /> },
        { path: "approve", element: <LoanRequestAddDataApprove /> }
      ]
    },
    {
      path: "loan/request/:id/:borrowerId",
      element: <LayoutLoanRequestAddUser />,
      children: [
        { element: <Navigate to="information" />, index: true },
        { path: "information", element: <LoanRequestAddUserInformation /> },
        { path: "address", element: <LoanRequestAddUserAddress /> },
        { path: "requestloan", element: <LoanRequestAddUserRequestLoan /> },
        { path: "documents", element: <LoanRequestAddUserDocuments /> }
      ]
    },
    { path: "loan/case/list", element: <LoanCaseList /> },
    { path: "loan/status/list/:caseCode", element: <LoanStatusList /> },
    {
      path: "loan/status/:caseCode/:loanId/:bankCode",
      element: <LayoutLoanStatusData />,
      children: [
        { element: <Navigate to="roomplan" />, index: true },
        { path: "roomplan", element: <LoanStatusDataRoomplan /> },
        { path: "loan", element: <LoanStatusDataLoan /> },
        { path: "user", element: <LoanStatusDataBorrower /> },
        { path: "collateral", element: <LoanStatusDataCollateral /> },
        { path: "status", element: <LoanStatusDataStatus /> }
      ]
    },
    { path: "report", element: <Report /> }
    // { path: "*", element: <NotFound /> }
  ]
};

const ProfileRoutes = {
  path: "/profile",
  element: protectedLayoutHideSidebar,
  children: [{ path: "/profile", element: <Profile /> }]
};

const NotificationsRoutes = {
  path: "/notifications",
  element: protectedLayoutHideSidebar,
  children: [{ path: "/notifications", element: <Notifications /> }]
};

const AuthRoutes = {
  path: "/auth",
  element: <AuthLayout />,
  children: [
    { element: <Navigate to="login" />, index: true },
    { path: "login", element: <LoginForm /> }
  ]
};
const SetPasswordRoutes = {
  path: "/",
  element: <AuthLayout />,
  children: [
    { path: "users/verify/:token", element: <VerifyPasswordForm /> },
    { path: "users/reset/:token", element: <ResetPasswordForm /> },
    { path: "user/forgot-password", element: <ForgotPasswordForm /> }
  ]
};

const NotFoundRoutes = {
  path: "/",
  element: (
    <AuthChildren>
      <Outlet />
    </AuthChildren>
  ),
  children: [{ path: "*", element: <NotFound /> }]
};

const ThemeRoutes = () => {
  // const [myState, setState] = useState(skipToken) // initialize with skipToken to skip at first
  // const { data: profile, isFetching } = useProfileQuery();
  // undefined, { skip: true }

  return createBrowserRouter([
    // profile?.user?.role_id === 1 || process.env.REACT_APP_NODE_ENV === "production-dev" ?
    MainRoutes,
    //  : MainRoutesNotSetting,
    AuthRoutes,
    SetPasswordRoutes,
    ProfileRoutes,
    NotificationsRoutes,
    NotFoundRoutes
  ]);
};

export default ThemeRoutes;
