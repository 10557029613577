import { CreateSettingUser, DataSettingUser, EditSettingUser, SettingUser } from "src/models/api/settingUser.model";
import { api } from "src/store/services/api";

export const settingUsersApi = api.enhanceEndpoints({ addTagTypes: ["SettingUser"] }).injectEndpoints({
  endpoints: (builder) => ({
    settingUsers: builder.query<DataSettingUser, void>({
      query: () => "internals/master/user",
      providesTags: ["SettingUser"]
    }),
    settingUser: builder.query<any, string | number>({
      query: (id) => `internals/master/user/${id}`,
      // keepUnusedDataFor: 0,
      providesTags: ["SettingUser"]
    }),
    deleteSettingUser: builder.mutation<void, string | number>({
      query: (userId) => ({
        url: "internals/master/user/delete",
        method: "POST",
        body: { userId: userId }
      }),
      invalidatesTags: ["SettingUser"]
    }),
    createSettingUser: builder.mutation<void, CreateSettingUser>({
      query: (createSettingUser) => ({
        url: "internals/master/user/add",
        method: "POST",
        body: createSettingUser
      }),
      invalidatesTags: ["SettingUser"]
    }),
    updateSettingUser: builder.mutation<void, EditSettingUser>({
      query: (editSettingUser) => ({
        url: "internals/master/user/edit",
        method: "POST",
        body: editSettingUser
      }),
      invalidatesTags: ["SettingUser"]
    })
  })
});

export const {
  useSettingUsersQuery,
  useSettingUserQuery,
  useDeleteSettingUserMutation,
  useCreateSettingUserMutation,
  useUpdateSettingUserMutation
} = settingUsersApi;
