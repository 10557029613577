import { configureStore } from "@reduxjs/toolkit";
import { contactsApi } from "src/store/services/contactsApi";
import { useDispatch } from "react-redux";
import loanReducer from "../store/slice/loanRequestSlice";
import loaderReducer from "../store/slice/loaderSlice";
import { loansApi } from "src/store/services/loansApi";
import { banksApi } from "src/store/services/banksApi";
import { listenerMiddleware } from "src/store/middleware/ListenerMiddleware";
import { api, apiSetting } from "src/store/services/api";
import { authApi } from "src/store/services/authApi";
import auth from "src/store/slice/authSlice";
import borrower from "src/store/slice/borrowerSlice";
import { masterApi } from "src/store/services/masterApi";
import { profileApi } from "src/store/services/profile/profileApi";
import { settingRolesApi } from "src/store/services/setting/settingRolesApi";
import { settingUsersApi } from "src/store/services/setting/settingUsersApi";
import { setPasswordApi } from "src/store/services/setPasswordApi";
import { settingModuleApi } from "./services/setting/settingModuleApi";
import { settingRolesPermissionApi } from "./services/setting/settingRolesPermissionApi";
import { configApi } from "./services/configApi";
import { notiApi } from "./services/notiApi";
import { reportsApi } from "./services/reportsApi";
export const store = configureStore({
  reducer: {
    auth,
    borrower,
    loanReducer,
    loaderReducer,
    [api.reducerPath]: api.reducer,
    [apiSetting.reducerPath]: apiSetting.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [contactsApi.reducerPath]: contactsApi.reducer,
    [loansApi.reducerPath]: loansApi.reducer,
    [banksApi.reducerPath]: banksApi.reducer,
    [masterApi.reducerPath]: masterApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [settingModuleApi.reducerPath]: settingModuleApi.reducer,
    [settingRolesApi.reducerPath]: settingRolesApi.reducer,
    [settingRolesPermissionApi.reducerPath]: settingRolesPermissionApi.reducer,
    [settingUsersApi.reducerPath]: settingUsersApi.reducer,
    [setPasswordApi.reducerPath]: setPasswordApi.reducer,
    [notiApi.reducerPath]: notiApi.reducer,
    [reportsApi.reducerPath]: reportsApi.reducer
  },
  middleware: (getDefault) =>
    getDefault()
      .concat([
        api.middleware,
        apiSetting.middleware,
        authApi.middleware,
        contactsApi.middleware,
        loansApi.middleware,
        banksApi.middleware,
        masterApi.middleware,
        profileApi.middleware,
        settingModuleApi.middleware,
        settingRolesApi.middleware,
        settingRolesPermissionApi.middleware,
        settingUsersApi.middleware,
        setPasswordApi.middleware,
        configApi.middleware,
        notiApi.middleware,
        reportsApi.middleware
      ])
      .prepend(listenerMiddleware.middleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;