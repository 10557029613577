import { createGlobalStyle } from "styled-components";
import { resetCss } from "./resetCss";
import { sidebarToggler } from "./sidebarToggler";
import { lightThemeVariables, commonThemeVariables } from "./themes/themeVariables";
export default createGlobalStyle`

  ${resetCss}

  [data-theme='light'],
  :root {
    ${lightThemeVariables}
  }

  
  :root {
    ${commonThemeVariables}
  } 


  input.ant-input::placeholder {
    color:var(--placeholder-color)
  }

  ${sidebarToggler}

  .disabled-row {
    background-color: #fafafa;
    pointer-events: none;
  }
  .display-none {
    display: none;
  }
  .row-gray {
    background-color: #fafafa;
  }
  &.ant-switch.ant-switch-disabled * {
    cursor: default;
  }

  .reg_icon {
    svg {
        font-size: 110px !important;
    }
  }
  .small_icon {
    svg {
        font-size: 18px !important;
    }
  }
`;
