import { string } from "prop-types";
import { CreateSettingRole, DataSettingRole, EditSettingRole, SettingRole, DataSettingRoleUser } from "src/models/api/settingRole.model";
import { api } from "src/store/services/api";

export const settingRolesPermissionApi = api.enhanceEndpoints({ addTagTypes: ["SettingRolePermission"] }).injectEndpoints({
  endpoints: (builder) => ({
    getRolePermission: builder.query<any, string | number>({
      query: (id) => `internals/master/role-permission/${id}`,
      providesTags: ["SettingRolePermission"]
    }),
    updateRolePermission: builder.mutation<void, any>({
      query: (data) => ({
        url: "internals/master/role-permission/add",
        method: "POST",
        body: data
      }),
      invalidatesTags: ["SettingRolePermission"]
    }),
    setRolePermission: builder.mutation<void, any>({
      query: (data) => ({
        url: "internals/master/role-permission/main",
        method: "POST",
        body: data
      }),
      invalidatesTags: ["SettingRolePermission"]
    })
  })
});

export const { useGetRolePermissionQuery, useUpdateRolePermissionMutation, useSetRolePermissionMutation } = settingRolesPermissionApi;
