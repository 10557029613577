import { useMemo } from "react";
import {
  useProfileDevelopQuery,
  useProfileQuery,
  useSetChangePasswordMutation,
  useUpdateProfileDevelopMutation,
  useUpdateProfileMutation
} from "src/store/services/profile/profileApi";

const useProfile = () => {
  const { data, refetch, isLoading, fulfilledTimeStamp, error } = useProfileQuery();
  const { data: profileDevelop } = useProfileDevelopQuery();
  const [setChangePassword] = useSetChangePasswordMutation();
  const [updateProfile] = useUpdateProfileMutation();
  const [updateProfileDevelop] = useUpdateProfileDevelopMutation();

  return useMemo(() => {
    return {
      data,
      refetch,
      isLoading,
      fulfilledTimeStamp,
      error,
      setChangePassword,
      profileDevelop,
      updateProfile,
      updateProfileDevelop
    };
  }, [data, refetch, isLoading, fulfilledTimeStamp, error, setChangePassword, profileDevelop, updateProfile, updateProfileDevelop]);
};

export default useProfile;
